<template>
  <div class="assemblyEdit dataM">
    <div class="content">
      <div class="title">
        产品基本信息
      </div>
      <div class="info">
        <div class="item" v-for="(value,key,index) in details" :key="index">
          <label>{{value}}：</label>
          <span>{{info[key]}}</span>
        </div>
      </div>
    </div>
    <div class="process">
      <div class="title">
        工序列表
        <div class="tool">
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入部门/工序名称"
            @keydown.enter.native="getProcessData(1)"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="getProcessData(1)"></i>
          </el-input>
        </div>
      </div>
      <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%">
        <el-table-column label="序号" type="index" width="80">
          <template slot-scope="scope">
            <span>{{((page-1)*10+scope.$index+1) < 10 ? `0${(page-1)*10+scope.$index+1}` : (page-1)*10+scope.$index+1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sequence" label="装配顺序" min-width="120"/>
        <el-table-column prop="department" label="执行部门" min-width="120"/>
        <el-table-column prop="procedureName" label="工序名称" min-width="120"/>
        <el-table-column prop="procedureTime" label="工序时间(分钟/套)" min-width="120"/>
        <el-table-column prop="demandQuantity" label="需求数量" min-width="120"/>
        <el-table-column prop="staffQuantity" label="需求人数" min-width="120"/>
        <el-table-column prop="assistantResources" label="辅助工具" min-width="120"/>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="getProcessData"/>
    </div>
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
import { getAssemblyDetails} from '@/api/data.js'

export default {
  name:"assemblyEdit",
  components:{
    pagination
  },
  data(){
    return{
      details:{
        "partCode": "图 号",
        "productName": "产品名称",//
        "materiaNo": "物料编码",//
        "versionCode": "版本号",
      },
      info:{
        "partCode": "",
        "productName": "",//
        "materiaNo": "",//
        "versionCode": "",
      },
      tableData:[],
      searchValue:'',
      total:0,
      page:1,
      size:10,
      routeData:{}
    }
  },
  watch:{
    size(){
      this.getProcessData(1)
    }
  },
  activated(){
    this.routeData = this.$route.query
    this.info = this.$route.query
    this.getProcessData()
  },
  methods:{
    //工序列表
    getProcessData(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        id:this.routeData.id,
        searchValue :this.searchValue
      }
      getAssemblyDetails(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
  }
}
</script>

<style lang="scss">
.assemblyEdit{
  text-align: left;
  padding: 18px;
  color: #E8E8E8;
  img{
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content{
    background: #232323;
    padding: 25px 35px;
    .info{
      display: flex;
      flex-wrap: wrap;
      .item{
        width: 30%;
        label{
          display: inline-block;
          min-width: 80px;
          max-width: max-content;
        }
      }
    }
  }
  .process{
    background: #232323;
    padding: 25px 35px;
    margin-top:10px;
    min-height: calc(100vh - 440px);
    position: relative;
    padding: 25px 35px 80px 35px;
    .title{
      display: flex;
      justify-content: space-between;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
      img{
        width: 22px;
        height: 22px;
        margin: 0 15px;
        cursor: pointer;
      }
    }
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
  }
}
</style>